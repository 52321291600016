import { useEffect, useState } from 'react'
import { routes } from '@shared/constants/routes'
import { getUtmParams } from '@/utils/url'
import { parseUrl, stringify } from '@/utils/queryString'

export const useUtmUrl = (pathname: string) => {
  const [url, setUrl] = useState(pathname)
  const { baseUrl, fragment, queryString } = splitUrl(pathname)

  // Build URL inside of useEffect to ensure access to `window` object
  useEffect(() => {
    const utmUrl = buildUtmUrl(
      `${baseUrl}${queryString ? '?' + queryString : ''}`
    )
    const newUrl = `${utmUrl}${fragment ? '#' + fragment : ''}`

    if (url !== newUrl) setUrl(newUrl)
  }, [pathname, baseUrl, fragment, queryString, url])

  return url
}

const buildUtmUrl = (pathname: string) => {
  const parsedUrl = parseUrl(pathname)
  const queryParams = getUtmParams(pathname.includes(routes.SIGN_UP.url))
  const query = stringify({ ...queryParams, ...parsedUrl.query })
  return query.length ? `${parsedUrl.url}?${query}` : pathname
}

const splitUrl = (pathname: string) => {
  const [baseUrl, fragmentAndAfter] = pathname.split('#')
  const [fragment, queryString] = fragmentAndAfter?.split('?') || []
  return { baseUrl, fragment, queryString }
}
